import React from "react";
import Img, { FluidObject } from "gatsby-image";

interface FeatureProps {
  title: string;
  featuresList: string[];
  img?: FluidObject;
  reverse?: boolean;
  dataCy: string;
}

const Feature: React.FC<FeatureProps> = ({
  title,
  img,
  featuresList,
  reverse,
  dataCy,
}) => {
  const rev = !!reverse;
  return (
    <section id={`feature-${title}`} data-cy={dataCy}>
      <div
        className={`flex flex-col ${
          !rev ? "lg:flex-row" : "lg:flex-row-reverse"
        } lg:my-12 w-full`}
      >
        <div className='block w-10/12 lg:w-full mx-auto'>
          {img ? <Img fluid={img} className='shadow shadow-2xl' /> : <span />}
        </div>
        <div className='mx-auto my-4 w-10/12 md:w-full'>
          <h4 className='text-2xl my-5 text-center font-primary text-white font-extrabold'>
            {title}
          </h4>
          <ul className='flex flex-col justify-around'>
            {featuresList?.map((elem, index) => (
              <li
                id={Math.random().toString(32)}
                key={index.toString()}
                className='flex flex-row text-white items-center font-primary my-1'
              >
                <div className='inline-flex mx-auto justify-around'>
                  <div className='inline-block text-white my-auto mr-2'>
                    <svg fill='white' viewBox='0 0 20 20' className='w-5'>
                      <path
                        /* eslint-disable-next-line max-len */
                        d='M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                        clipRule='evenodd'
                        fillRule='evenodd'
                      />
                    </svg>
                  </div>
                  <span className='w-64'>{elem}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Feature;
