import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Feature from "../components/features";
import SEO from "../components/seo";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      mapImage: file(relativePath: { eq: "images/map.webp" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chartImage: file(relativePath: { eq: "images/barchart.png" }) {
        childImageSharp {
          fluid(toFormat: WEBP, maxWidth: 420, webpQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        lang='en'
        meta={[]}
        title='Product presentation'
        description='A data-exploration platform'
      />
      <Layout showBreak>
        <div className='inline-block w-full bg-grad'>
          <section
            className='inline-block w-full'
            id='information'
            data-cy='information'
          >
            <div className='w-full'>
              <div className='block mx-auto md:max-w-5xl'>
                <div className='flex flex-col lg:flex-row px-5 items-center text-white font-primary'>
                  <section id='hero' data-cy='hero'>
                    <div className='block lg:flex'>
                      <h1 className='block leading-snug text-center text-4xl my-8 sm:text-4xl font-extrabold sm:my-12 lg:w-1/2 px-3'>
                        Give your numbers a{" "}
                        <span className='bg-white starter'>story.</span>
                        <br />
                        And your stories{" "}
                        <span className='bg-white starter'>numbers. </span>
                      </h1>
                      <h2 className='text-xl block mx-auto sm:w-10/12 text-center lg:m-0 lg:my-auto lg:w-1/2 px-3'>
                        Dig Stories is a platform that allows you to explore
                        data from various datasets - combine, visualize and
                        analyze all in one tool.
                      </h2>
                    </div>
                  </section>
                </div>
                <section id='features' data-cy='features'>
                  <div className='my-8'>
                    <Feature
                      title='Visualize data in many forms'
                      img={data.mapImage.childImageSharp.fluid}
                      featuresList={[
                        "Line, Scatter and Area charts",
                        "Scientific Visualization",
                        "Geographic projections with GeoJSON or TopoJSON",
                        "Highly performant large dataset visualization",
                      ]}
                      dataCy='charts-feature'
                    />
                    <Feature
                      title='Extract Information'
                      img={data.chartImage.childImageSharp.fluid}
                      featuresList={[
                        "Supreme, schema-introspection engine",
                        "Filter data by any combination of variables",
                        "Group your data by clusters",
                        "Advanced queries",
                        "Performant map-reduce operations on large datasets",
                      ]}
                      reverse
                      dataCy='queries-feature'
                    />
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section
            id='about'
            className='block w-full lg:max-w-5xl mx-auto px-4 lg:px-0 mb-12'
            data-cy='about'
          >
            <div className='flex flex-col font-primary leading-relaxed w-full mx-auto bg-white shadow shadow-2xl p-4'>
              <h2 className='text-4xl font-extrabold starter'>About</h2>
              <p data-cy='what-is-a-story'>
                A story in our context is one of data. Millenium Foundation
                Kosovo undertook one of the most massive data collection surveys
                in Kosovo, and they collected information about 32k people. MFK
                has provided this dataset to the public, and we have taken it in
                its raw form, processed it further down the pipelines and made
                it ready to serve. In our database, we currently have more than
                8k records of a cleaned, post-processed dataset, where you can
                explore an almost endless combinations of demographic filters.
              </p>
              <br />
              <p data-cy='follow-up'>
                In this way, you can either browse some of our own stories,
                which we have created in-house, or use our tools to create your
                own stories that are in your field of interest.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};
